import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import styles from "../../pages/GradedEssay.module.css";
import { filterAIOutput } from "../../utils/filterAIOutputt";

// Register the necessary elements
Chart.register(ArcElement, Tooltip, Legend);

function DonutChart(props) {
  const { data, totalScore, contentScore, languageScore, onEditMarks} = props;

  const points = parseInt(data?.pointSystem.slice(0, 2));
  const languageScoreMax = points / 2;
  const contentScoreMax = points / 2;
  
  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);
  const chart3Ref = useRef(null);

  useEffect(() => {
    const chart1Instance = chart1Ref.current?.chartInstance;
    const chart2Instance = chart2Ref.current?.chartInstance;
    const chart3Instance = chart3Ref.current?.chartInstance;

    return () => {
      if (chart1Instance) chart1Instance.destroy();
      if (chart2Instance) chart2Instance.destroy();
      if (chart3Instance) chart3Instance.destroy();
    };
  }, []);

  useEffect(() => {
    console.log(data);
    console.log("Scores edited", data?.processedOutputAI);
  },[data?.processedOutputAI]);

  const data1 = {
    datasets: [
      {
        data: [totalScore, points - totalScore],
        backgroundColor: ["#A69164", "rgba(0, 0, 0, 0.38)"],
        borderWidth: 0,
      },
    ],
  };

  const data2 = {
    datasets: [
      {
        data: [languageScore, languageScoreMax - languageScore],
        backgroundColor: ["#A69164", "rgba(0, 0, 0, 0.38)"],
        borderWidth: 0,
      },
    ],
  };

  const data3 = {
    datasets: [
      {
        data: [contentScore, contentScoreMax - contentScore],
        backgroundColor: ["#A69164", "rgba(0, 0, 0, 0.38)"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "60%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <div className={styles.sectionTitle}>
        <span>Grades</span>
        <span className={styles.studentDetails}>
          Total Score & Subscores
          {/* Student Name - Class - Assignment Name */}
        </span>
      </div>
      <section className={styles.feedbackSection}>
        <div className={styles.feedbackContent}>
          <div className={styles.donutCharts}>
            <div className={styles.donutChart} onClick={onEditMarks} style={{ cursor: 'pointer' }}>
              <Doughnut data={data1} options={options} ref={chart1Ref} />
              <div className={styles.donutLabelWrapper}>
                <div
                  className={styles.donutLabel}
                >{`${totalScore}/${points}`}</div>
                <div className={styles.donutLabelTitle}>Total Score</div>
              </div>
            </div>
            <div className={styles.donutChart} onClick={onEditMarks} style={{ cursor: 'pointer' }}>
              <Doughnut data={data2} options={options} ref={chart2Ref} />
              <div className={styles.donutLabelWrapper}>
                <div
                  className={styles.donutLabel}
                >{`${languageScore}/${languageScoreMax}`}</div>
                <div className={styles.donutLabelTitle}>Language Score</div>
              </div>
            </div>
            <div className={styles.donutChart} onClick={onEditMarks} style={{ cursor: 'pointer' }}>
              <Doughnut data={data3} options={options} ref={chart3Ref} />
              <div className={styles.donutLabelWrapper}>
                <div
                  className={styles.donutLabel}
                >{`${contentScore}/${contentScoreMax}`}</div>
                <div className={styles.donutLabelTitle}>Content Score</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DonutChart;