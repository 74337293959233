// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 100px;
  background-color: #f8f8f8;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

.sidebar-logo {
  width: 60px;
  height: auto;
  margin-bottom: 30px;
  /* Hide the logo */
  visibility: hidden; 
}

.sidebar-button {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
}

.sidebar-button img {
  width: 40px;
  height: auto;
}

.sidebar-button:hover {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.centre-buttons{
  height: 10%;
  display:flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".sidebar {\n  width: 100px;\n  background-color: #f8f8f8;\n  padding: 20px 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  height: 100vh;\n}\n\n.sidebar-logo {\n  width: 60px;\n  height: auto;\n  margin-bottom: 30px;\n  /* Hide the logo */\n  visibility: hidden; \n}\n\n.sidebar-button {\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 30px;\n  border: none;\n  background: none;\n  cursor: pointer;\n  color: inherit;\n}\n\n.sidebar-button img {\n  width: 40px;\n  height: auto;\n}\n\n.sidebar-button:hover {\n  background-color: #e0e0e0;\n  border-radius: 5px;\n}\n\n.centre-buttons{\n  height: 10%;\n  display:flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
