import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewIcon from "../assets/view.svg";
import TranshCanIcon from "../assets/trash-can.svg";
import sortIcon from "../assets/sort.svg";
import "./Table.css";

function Table({ assignments, onDelete }) {
  const [sortOrder, setSortOrder] = useState("asc");

  const rowHeight = 50;
  const tableHeight = assignments.length * rowHeight;

  if (!Array.isArray(assignments)) {
    return <div>No assignments available</div>;
  }

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    assignments.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  return (
    <table className="assignment-table" style={{ height: `${tableHeight}px` }}>
      <thead>
        <tr>
          <th>
            Upload Date
            <button onClick={handleSort} className="sort-button">
              <img src={sortIcon} alt="Sort" className="sort-icon" />
            </button>
          </th>
          <th>Assignment Name</th>
          <th>Student Name</th>
          <th>Class</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {assignments.map((assignment, index) => (
          <tr key={index} style={{ height: `${rowHeight}px` }}>
            <td>{new Date(assignment.createdAt).toLocaleDateString()}</td>
            <td>{assignment.essayName}</td>
            <td>{assignment.studentName}</td>
            <td>{assignment.className}</td>
            <td
              className={
                assignment.processState === "processed"
                  ? "completed"
                  : assignment.processState === "processing"
                  ? "processing"
                  : "failed"
              }
            >
              {assignment.processState === "processed"
                ? "Completed"
                : assignment.processState === "processing"
                ? "Processing"
                : "Failed"}
            </td>
            <td>
              <a
                href="#delete"
                onClick={() => {
                  console.log(assignment._id);
                  onDelete(assignment._id);
                }}
              >
                <img src={TranshCanIcon} alt="Delete" className="view-icon" />
              </a>{" "}
              {assignment.processState === "processed" && (
                <>
                  <Link
                    to={`/GradedEssay/${assignment._id}`}
                    state={{ Assignment: assignment }}
                  >
                    <img src={ViewIcon} alt="View" className="view-icon" />
                  </Link>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
