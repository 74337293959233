import React, { useState } from "react";
import styles from '../../pages/GradedEssay.module.css';

function EditModal({ contentScore, languageScore, maxPoints, onSubmit, onClose}) {
  const [newContentScore, setNewContentScore] = useState(contentScore);
  const [newLanguageScore, setNewLanguageScore] = useState(languageScore);
  const [errorMessage, setErrorMessage] = useState(""); // To store validation error message

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isNaN(newContentScore) || isNaN(newLanguageScore)) {
        setErrorMessage("Please enter valid scores.");
        return;
    }

    // Validate that the scores are within the allowed range
    if (newContentScore > maxPoints || newLanguageScore > maxPoints) {
      setErrorMessage(`Scores cannot exceed ${maxPoints}. Please enter valid scores.`);
      return;
    }

    // Reset error message if validation passes
    setErrorMessage("");

    // Call the parent's onSubmit function with the new scores
    onSubmit(newContentScore, newLanguageScore);

    // Close the modal after submitting successfully
    onClose();
    
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalTitle}>Edit Scores</div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="languageScore">Language Score (Max: {maxPoints})</label>
            <input
              type="number"
              id="languageScore"
              value={newLanguageScore}
              onChange={(e) => setNewLanguageScore(parseFloat(e.target.value))}
              min="0"
              max={maxPoints}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="contentScore">Content Score (Max: {maxPoints})</label>
            <input
              type="number"
              id="contentScore"
              value={newContentScore}
              onChange={(e) => setNewContentScore(parseFloat(e.target.value))}
              min="0"
              max={maxPoints}
            />
          </div>

          {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

          <div className={styles.modalActions}>
            <button type="submit" className={styles.submitButton}>Submit</button>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditModal;
