import React, { useState } from "react";
import styles from "../../pages/GradedEssay.module.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function PDFViewer(props) {
  const { data } = props;
  const fileUrl = data?.fileUrl || "/";
  const [error, setError] = useState(null);

  // Initialize the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const onLoadError = (error) => {
    console.error("Error loading PDF:", error.message);
    setError("Error loading PDF");
  };

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.labelContent}>
        <div className={styles.sectionTitle}>Labelled Essay:</div>
        <div>
          <button className={styles.btnEdit} onClick={props.handleEditPdf}>Edit</button>
        </div>
      </div>
      <div className={styles.headerContent}>
        <div className={styles.pdfViewer}>
          {error ? (
            <div style={{ color: "red" }}>{error}</div>
          ) : (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={fileUrl}
                plugins={[defaultLayoutPluginInstance]}
                onLoadError={onLoadError}
              />
            </Worker>
          )}
        </div>
      </div>
    </header>
  );
}

export default PDFViewer;
