import React from 'react';
import styles from '../../pages/GradedEssay.module.css';

function Parameters(props) {
  const {data} = props;
  return (
    <div style={{ marginTop: '40px' }}>
      <div className={styles.sectionTitle}>
        <span>Parameters</span>
      </div>
      <div className={styles.parametersContent}>
        <p>
          Grading Instruction: {data?.customInstructions}<br />
          Point System: {data?.pointSystem}<br />
          Essay Type: {data?.essayType}<br />
          Award Points For: {data?.awardPoints.toString()}
        </p>
      </div>
    </div>
  );
}

export default Parameters;