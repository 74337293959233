import React from "react";
import styles from '../../pages/GradedEssay.module.css';

function RubricsModal({ languageScore, contentScore, onClose }) {
  return (
    <div className={styles.RubricmodalOverlay}>
      <div className={styles.RubricmodalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.rubricContainer}>
          <h1> Grading Rubric </h1>
          <table>
            <thead>
              <tr>
                <th>Mark Range</th>
                <th>Content (10 Marks)</th>
                <th>Language (10 Marks)</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.markRange} id="range-9-10">
                <td>9 - 10</td>
                <td className={contentScore >= 9 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are <strong>highly</strong> clear and logical</li>
                    <li> Thoroughly developed content</li>
                    <li> Topic and <strong><u>at least one picture</u></strong> are addressed</li>
                  </ul>
                </td>
                <td className={languageScore >= 9 ? styles.highlight : ""}>
                  <ul>
                    <li> Sentences and expressions are very well written </li>
                    <li> Hardly any errors in grammar, spelling, and punctuation </li>
                    <li> Excellent range of vocabulary </li>
                    <li> Very good sequencing and paragraphing of ideas </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-8">
                <td>8</td>
                <td className={contentScore === 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are mostly interesting, clear, and logical</li>
                    <li> Well-developed content</li>
                    <li> Topic and <strong><u>at least one picture</u></strong> are addressed</li>
                  </ul>
                </td>
                <td className={languageScore === 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Sentences and expressions are mostly well written </li>
                    <li> Minor errors in grammar, spelling, and punctuation that do not affect the understanding</li>
                    <li> Appropriate range of vocabulary </li>
                    <li> Good sequencing and paragraphing of ideas </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-6-7">
                <td>6 - 7</td>
                <td className={contentScore >= 6 && contentScore < 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are generally clear and understandable</li>
                    <li> Sufficiently developed content</li>
                    <li> Topic and <strong><u>at least one picture</u></strong> are addressed</li>
                  </ul>
                </td>
                <td className={languageScore >= 6 && languageScore < 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Sentences and expressions are under-developed </li>
                    <li> Several errors in grammar, spelling, and punctuation that affect the understanding of the story</li>
                    <li> Range of vocabulary is mundane </li>
                    <li> Weak sequencing and paragraphing of ideas </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-3-5">
                <td>3 - 5</td>
                <td className={contentScore >= 3 && contentScore < 6 ? styles.highlight : ""}>
                  <ul>
                    <li> <strong>Some</strong> ideas are relevant</li>
                    <li> Story is mostly unclear and illogical</li>
                    <li> Undeveloped content</li>
                    <li> Topic and <strong><u>at least one picture</u></strong> are <strong>not</strong> addressed</li>
                  </ul>
                </td>
                <td className={languageScore >= 3 && languageScore < 6 ? styles.highlight : ""}>
                  <ul>
                    <li> Sentences and expressions are under-developed </li>
                    <li> Several errors in grammar, spelling, and punctuation that affect the understanding of the story</li>
                    <li> Range of vocabulary is mundane </li>
                    <li> Weak sequencing and paragraphing of ideas </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-1-2">
                <td>1 - 2</td>
                <td className={contentScore >= 1 && contentScore < 3 ? styles.highlight : ""}>
                  <ul>
                    <li> <strong>Most</strong> ideas are irrelevant to the topic</li>
                    <li> Story is unclear and confusing</li>
                    <li> Topic and <strong><u>at least one picture</u></strong> are <strong>not</strong> addressed</li>
                  </ul>
                </td>
                <td className={languageScore >= 1 && languageScore < 3 ? styles.highlight : ""}>
                  <ul>
                    <li> Sentences and expressions are poorly written </li>
                    <li> Full of errors in grammar, spelling, and punctuation that affect the understanding of the story</li>
                    <li> Range of vocabulary is very limited </li>
                    <li> Very poor sequencing and paragraphing of ideas </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RubricsModal;