import React from 'react';
import styles from '../../pages/Upload.module.css';

const InputField = ({ label, placeholder, onChange }) => {
  return (
    <div className={styles.inputWrapper}>
      <label className={styles.inputLabel}>{label}</label>
      <input type="text" className={styles.inputField} placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default InputField;