// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignment-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 16px
  }
  
  .assignment-table th, .assignment-table td {
    border: none;
    padding: 8px;
    text-align: left;
  }
  
  .assignment-table th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    font-size: 18px;
  }
  
  .assignment-table td {
    background-color: #fff;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
  }
  
  .assignment-table tbody tr:nth-child(even) td {
    background-color: #f9f9f9;
  }
  
  .assignment-table .completed {
    color: green;
    font-weight: bold;
  }
  
  .assignment-table .processing {
    color: orange;
    font-weight: bold;
  }

  .assignment-table .failed {
    color: red;
    font-weight: bold;
  }

  .view-icon {
    height: 35px;
    width: 25px;
  }

  .sort-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-bottom: 8px;
    vertical-align: middle;
  }

  `, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,8BAA8B;IAC9B;EACF;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,gCAAgC;IAChC,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;EACxB","sourcesContent":[".assignment-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n    font-family: Arial, sans-serif;\n    font-size: 16px\n  }\n  \n  .assignment-table th, .assignment-table td {\n    border: none;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .assignment-table th {\n    background-color: #f2f2f2;\n    color: black;\n    font-weight: bold;\n    font-size: 18px;\n  }\n  \n  .assignment-table td {\n    background-color: #fff;\n    color: #333;\n    border-bottom: 1px solid #e0e0e0;\n    font-size: 16px;\n  }\n  \n  .assignment-table tbody tr:nth-child(even) td {\n    background-color: #f9f9f9;\n  }\n  \n  .assignment-table .completed {\n    color: green;\n    font-weight: bold;\n  }\n  \n  .assignment-table .processing {\n    color: orange;\n    font-weight: bold;\n  }\n\n  .assignment-table .failed {\n    color: red;\n    font-weight: bold;\n  }\n\n  .view-icon {\n    height: 35px;\n    width: 25px;\n  }\n\n  .sort-button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    width: 16px;\n    height: 16px;\n    margin-left: 8px;\n    margin-bottom: 8px;\n    vertical-align: middle;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
