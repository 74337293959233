/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import jsPDF from "jspdf";
import * as pdfjsLib from "pdfjs-dist";
import styles from "../../pages/GradedEssay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRotateLeft,
  faArrowRotateRight,
  faCompress,
  faDoorOpen,
  faExpand,
  faMousePointer,
  faPen,
  faRotate,
  faSave,
  faTextHeight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { restoreDocument, updateGradedDocument } from "../../API/api";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const PdfEditor = (props) => {
  const { data, expand } = props;
  const { id } = useParams();
  const fileUrl = data?.fileUrl || "/";
  const [canvas, setCanvas] = useState(null);
  const canvasRef = useRef(null);
  const undoStack = useRef([]);
  const redoStack = useRef([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageHeight, setPageHeight] = useState(0);
  const [activeTool, setActiveTool] = useState("select");
  const [color, setColor] = useState("red");
  const [brushColor, setBrushColor] = useState("red");
  const [brushWidth, setBrushWidth] = useState(4);
  const [isSaving, setIsSaving] = useState(false);

  async function mergeImages(imageDataArray) {
    const canvas = new fabric.Canvas(null);

    let totalHeight = 0;
    let maxWidth = 0;

    // Load images one by one and calculate total dimensions
    const imageObjects = await Promise.all(
      imageDataArray.map((imgData, index) => {
        return new Promise((resolve, reject) => {
          fabric.Image.fromURL(imgData, (img) => {
            maxWidth = Math.max(maxWidth, img.width);
            totalHeight += img.height;
            setPageHeight(img.height * (expand ? 1.3 : 1.5));
            resolve(img);
          });
        });
      })
    );

    // Resize canvas based on total width and height
    canvas.setWidth(maxWidth);
    canvas.setHeight(totalHeight);

    // Add images to canvas, stacking vertically
    let currentHeight = 0;
    imageObjects.forEach((img) => {
      img.set({ top: currentHeight, left: 0 });
      canvas.add(img);
      currentHeight += img.height;
    });

    // Export the final merged image
    const finalImageData = canvas.toDataURL({
      format: "png",
      quality: 1,
    });

    return { image: finalImageData, width: maxWidth, height: totalHeight }; // Returns the final merged image
  }

  const renderSubToolbar = () => {
    switch (activeTool) {
      case "draw":
        return (
          <>
            <span
              className={
                brushColor === "black"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "black", borderColor: "red" }}
              onClick={() => setBrushColor("black")}
            />
            <span
              className={
                brushColor === "red"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "red" }}
              onClick={() => setBrushColor("red")}
            />
            <span
              className={
                brushColor === "yellow"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "yellow" }}
              onClick={() => setBrushColor("yellow")}
            />
            <span
              className={
                brushColor === "blue"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "blue" }}
              onClick={() => setBrushColor("blue")}
            />
            <span
              className={
                brushColor === "orange"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "orange" }}
              onClick={() => setBrushColor("orange")}
            />
            <div className={styles.seperator} />
            <span
              className={
                brushWidth === 2
                  ? styles.brushWidthSpanActive
                  : styles.brushWidthSpan
              }
              onClick={() => setBrushWidth(2)}
            >
              <span
                style={{ width: "2px", height: "2px" }}
                className={styles.brushWidthContent}
              />
            </span>
            <span
              className={
                brushWidth === 4
                  ? styles.brushWidthSpanActive
                  : styles.brushWidthSpan
              }
              onClick={() => setBrushWidth(4)}
            >
              <span
                style={{ width: "4px", height: "4px" }}
                className={styles.brushWidthContent}
              />
            </span>
            <span
              className={
                brushWidth === 6
                  ? styles.brushWidthSpanActive
                  : styles.brushWidthSpan
              }
              onClick={() => setBrushWidth(6)}
            >
              <span
                style={{ width: "6px", height: "6px" }}
                className={styles.brushWidthContent}
              />
            </span>
            <span
              className={
                brushWidth === 8
                  ? styles.brushWidthSpanActive
                  : styles.brushWidthSpan
              }
              onClick={() => setBrushWidth(8)}
            >
              <span
                style={{ width: "8px", height: "8px" }}
                className={styles.brushWidthContent}
              />
            </span>
          </>
        );
      default:
        return (
          <>
            <span
              className={
                color === "black"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "black", borderColor: "red" }}
              onClick={() => setColor("black")}
            />
            <span
              className={
                color === "red"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "red" }}
              onClick={() => setColor("red")}
            />
            <span
              className={
                color === "yellow"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "yellow" }}
              onClick={() => setColor("yellow")}
            />
            <span
              className={
                color === "blue"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "blue" }}
              onClick={() => setColor("blue")}
            />
            <span
              className={
                color === "orange"
                  ? styles.brushColorSpanActive
                  : styles.brushColorSpan
              }
              style={{ backgroundColor: "orange" }}
              onClick={() => setColor("orange")}
            />
          </>
        );
    }
  };

  // Load the PDF file into the canvas
  const loadPdf = async (resetPdf) => {
    const pdf = await pdfjsLib.getDocument(resetPdf || fileUrl).promise;
    const images = [];

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      const imgData = canvas.toDataURL(); // Get the image data
      images.push(imgData); // Store image data in the array
    }

    const finalImageData = await mergeImages(images); // Merge the images into a single image

    // Initialize the fabric canvas with the size of the PDF
    const fabricCanvas = new fabric.Canvas(canvasRef?.current, {
      width: finalImageData.width,
      height: finalImageData.height,
      backgroundImage: finalImageData.image,
      isDrawingMode: false, // Set initial drawing mode
    });

    const captureCanvasState = (e) => {
      const canvasJSON = fabricCanvas.toJSON();
      undoStack.current.push(canvasJSON);
    };

    // Add event listener to track object changes
    fabricCanvas.on("object:added", captureCanvasState);
    fabricCanvas.on("object:modified", captureCanvasState);
    fabricCanvas.on("object:removed", captureCanvasState);
    fabricCanvas.on("mouse:down", () => {
      redoStack.current = [];
    });

    // Configure brush settings
    fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
    fabricCanvas.freeDrawingBrush.width = 4;
    fabricCanvas.freeDrawingBrush.color = "red";

    setCanvas(fabricCanvas);
  };

  // Draw
  const draw = () => {
    setActiveTool("draw");
    canvas.isDrawingMode = true;
  };

  const close = () => {
    if (
      window.confirm(
        "Are you sure you want to exit the PDF editor? Any unsaved changes will be lost."
      )
    ) {
      props.setEditPdf(false);
      props.setExpand(false);
    }
  };

  // Select
  const select = () => {
    setActiveTool("select");
    canvas.isDrawingMode = false;
  };

  // Add Text to the Canvas
  const addText = () => {
    const scrollTop = document.getElementById("canvasScrollbar").scrollTop;
    const text = new fabric.IText("Edit me...", {
      left: 100,
      top: scrollTop + 100,
      fontSize: 22,
      fontWeight: "bold",
      fill: color,
    });
    canvas.isDrawingMode = false;
    setActiveTool("select");
    canvas.add(text);
    canvas.setActiveObject(text);
  };

  // Add a Rectangle to the Canvas
  const addRectangle = () => {
    const scrollTop = document.getElementById("canvasScrollbar").scrollTop;
    const rect = new fabric.Rect({
      left: 100,
      top: scrollTop + 50,
      width: 100,
      height: 20,
      fill: "transparent",
      stroke: color,
    });
    setActiveTool("select");
    canvas.isDrawingMode = false;
    canvas.add(rect);
  };

  // Export the FabricJS canvas and the PDF background to a new PDF
  const exportToPDF = () => {
    setIsSaving(true);

    const dataUrl = canvas.toDataURL({
      format: "png",
      multiplier: 1, // Increase resolution
    });

    const pageWidth = canvas.width * (expand ? 1.3 : 1.5);

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [pageWidth, pageHeight],
    });

    const img = new Image();
    img.src = dataUrl;

    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Calculate how many pages are needed
      const scaleFactor = pageWidth / imgWidth;
      const scaledHeight = imgHeight * scaleFactor;
      const numPages = Math.ceil(scaledHeight / pageHeight);

      const canvasEl = document.createElement("canvas");
      const ctx = canvasEl.getContext("2d");

      canvasEl.width = imgWidth;
      canvasEl.height = imgHeight;

      // Draw the full image on the canvas
      ctx.drawImage(img, 0, 0, imgWidth, imgHeight);

      // Loop through each page and add it to the PDF
      for (let page = 0; page < numPages; page++) {
        const pageCanvas = document.createElement("canvas");
        const pageCtx = pageCanvas.getContext("2d");

        pageCanvas.width = imgWidth;
        pageCanvas.height = pageHeight / scaleFactor;

        // Extract the part of the image for this page
        pageCtx.drawImage(
          img,
          0,
          -page * (pageHeight / scaleFactor), // Source position (move the image up for each page)
          imgWidth,
          imgHeight
        );

        const pageData = pageCanvas.toDataURL("image/png");

        if (page > 0) {
          doc.addPage();
        }

        doc.addImage(pageData, "PNG", 0, 0, pageWidth, pageHeight);
      }

      //Fetch update to db
      const pdfBlob = doc.output("blob");
      handleUpdateDocument(pdfBlob);
    };

    canvas.isDrawingMode = false;
  };

  const restore = async () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to restore the original PDF version? Any changes will be lost."
        )
      ) {
        setIsSaving(true);
        const response = await restoreDocument(id);
        setIsSaving(false);

        // reset canvas
        canvas.dispose();
        loadPdf(response?.fileUrl);

        return response;
      }
    } catch (error) {
      setIsSaving(false);
      console.error(error);
    }
  };

  const removeObject = () => {
    var activeObject = canvas.getActiveObject();
    if (!activeObject?._objects) {
      if (
        (activeObject.type === "textbox" || activeObject.type === "i-text") &&
        activeObject.isEditing
      ) {
        // Allow normal backspace behavior for text editing
        return;
      } else {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
    } else {
      activeObject?._objects.forEach((object) => {
        canvas.remove(object);
        canvas.renderAll();
      });
    }
  };

  const setExpand = () => {
    if (
      window.confirm(
        `Are you sure you want to ${
          expand ? "compress" : "expand"
        } the PDF editor? Any unsaved changes will be lost.`
      )
    ) {
      props.setExpand(!expand);
    }
  };

  const handleSetBrush = (brushColor, brushWidth) => {
    canvas.freeDrawingBrush.width = brushWidth;
    canvas.freeDrawingBrush.color = brushColor;
  };

  const handleSetGeneralColor = (color) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject?.type === "i-text") {
      // Set the new color
      activeObject.set("fill", color);

      // Re-render the canvas to reflect changes
      canvas.renderAll();
    } else if (activeObject && activeObject?.type === "rect") {
      // Set the new color
      activeObject.set("stroke", color);

      // Re-render the canvas to reflect changes
      canvas.renderAll();
    }
  };

  const handleUpdateDocument = async (document) => {
    try {
      const formData = new FormData();
      formData.append("file", document, "document.pdf");
      const response = await updateGradedDocument(id, formData);
      setIsSaving(false);
      return response;
    } catch (error) {
      setIsSaving(false);
      window.alert("Failed to update the document. Please try again.");
      console.error(error);
    }
  };

  const undo = () => {
    if (undoStack?.current.length > 1) {
      const previousState = undoStack?.current.pop(); // Pop current state
      const lastState = undoStack?.current[undoStack?.current.length - 1];
      redoStack?.current.push(previousState); // Save it in the redo stack
      undoStack.current = [];
      canvas.loadFromJSON(lastState); // Load the previous state
      canvas.renderAll();
    }
  };

  const redo = () => {
    if (redoStack?.current.length > 0) {
      const nextState = redoStack?.current.pop();
      undoStack.current = [];
      undoStack?.current.push(nextState); // Move state back to the undo stack
      canvas.loadFromJSON(nextState);
      canvas.renderAll();
    }
  };

  useEffect(() => {
    const scaleCanvasToScreen = () => {
      console.log("Scale canvas to screen");
      const canvasDiv = document.getElementsByClassName("canvas-container")[0];
      // Get the canvas width and the container width
      const containerWidth = expand ? window.screen.width - 200 : 700;
      const canvasWidth = canvasDiv.style.width.split("px")[0];

      console.log("canvasDiv: ", canvasDiv);

      // Calculate the scale factor based on the container's width and the canvas's original width
      const scaleFactor = containerWidth / canvasWidth;

      console.log("Scale factor:", scaleFactor);
      canvasDiv.style.transform = `scale(${scaleFactor})`;
      canvasDiv.style["transform-origin"] = "top left";
    };
    // Function to handle the backspace key press
    const handleKeyDown = (event) => {
      if (event.key === "Backspace" || event.key === "Delete") {
        removeObject();
      }

      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        event.preventDefault();
        undo();
      }

      // Check for Ctrl + Shift + Z or Command + Shift + Z for Redo
      if (
        (event.ctrlKey || event.metaKey) &&
        event.key === "Z" &&
        event.shiftKey
      ) {
        event.preventDefault();
        redo();
      }
    };

    // Attach the keydown event listener
    if (canvas) {
      scaleCanvasToScreen();
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [canvas]);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      canvas?.dispose();
      loadPdf();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && canvas) {
      handleSetBrush(brushColor, brushWidth);
    }
  }, [brushColor, brushWidth]);

  useEffect(() => {
    if (isLoaded && canvas) {
      handleSetGeneralColor(color);
    }
  }, [color]);

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.header_toolbar}>
        <h1 style={{ color: "black", textAlign: "center" }}>
          PDF Document Editor{" "}
          <ClipLoader size={14} color="#A69164" loading={isSaving} />
        </h1>
        <div className={styles.toolbar}>
          <button
            onClick={select}
            className={
              activeTool === "select" ? styles.activeButton : styles.button
            }
          >
            <FontAwesomeIcon icon={faMousePointer} /> {/* Select Icon */}
          </button>
          <button
            onClick={draw}
            disabled={isSaving}
            className={
              activeTool === "draw" ? styles.activeButton : styles.button
            }
          >
            <FontAwesomeIcon icon={faPen} /> {/* Draw Icon */}
          </button>
          <button
            onClick={addText}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={faTextHeight} /> {/* Add Text Icon */}
          </button>
          <button
            onClick={addRectangle}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={faSquare} /> {/* Add Rectangle Icon */}
          </button>
          <button
            onClick={removeObject}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={faTrashCan} /> {/* Add Rectangle Icon */}
          </button>
          <button onClick={undo} disabled={isSaving} className={styles.button}>
            <FontAwesomeIcon icon={faArrowRotateLeft} />{" "}
            {/* Add Rectangle Icon */}
          </button>
          <button onClick={redo} disabled={isSaving} className={styles.button}>
            <FontAwesomeIcon icon={faArrowRotateRight} />{" "}
            {/* Add Rectangle Icon */}
          </button>
          <button
            onClick={setExpand}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={expand ? faCompress : faExpand} />{" "}
            {/* Add Rectangle Icon */}
          </button>
          <button
            onClick={exportToPDF}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={faSave} /> {/* Add Rectangle Icon */}
          </button>
          <button
            onClick={restore}
            disabled={isSaving}
            className={styles.button}
          >
            <FontAwesomeIcon icon={faRotate} /> {/* Add Rectangle Icon */}
          </button>
          <button onClick={close} disabled={isSaving} className={styles.button}>
            <FontAwesomeIcon icon={faDoorOpen} /> {/* Add Rectangle Icon */}
          </button>
        </div>
        <div className={styles.sub_toolbar}>{renderSubToolbar()}</div>
      </div>

      <div
        className={
          expand ? styles.canvasScrollbarFullScreen : styles.canvasScrollbar
        }
        id={"canvasScrollbar"}
      >
        <canvas ref={canvasRef} />
      </div>
    </header>
  );
};

export default PdfEditor;
