const { initializeApp } = require("firebase/app");
const { getAuth } = require("firebase/auth");
const { getStorage } = require("firebase/storage");

const firebaseConfig = {
  apiKey: "AIzaSyBi6Yyh2zhoZn30RtaOBXT7ghnxsxpVVCY",
  authDomain: "essay-grader-cec50.firebaseapp.com",
  projectId: "essay-grader-cec50",
  storageBucket: "essay-grader-cec50.appspot.com",
  messagingSenderId: "960854862298",
  appId: "1:960854862298:web:0f05da0475bc1ae2ed78e3",
  measurementId: "G-1XCVF09ZM1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

module.exports = { auth, storage };