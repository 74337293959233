import React from 'react';
import './Sidebar.css';
import logout_image from '../assets/logout.svg';
import file_upload_image from '../assets/file-upload.svg';
import file_history_image from '../assets/clock.svg';
import teacher_dashboard_image from '../assets/dashboard.svg';
import TLL_logo from '../assets/tll-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../firebase";
import { signOut } from "firebase/auth";

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/Login');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="sidebar">
      <img src={TLL_logo} alt="TLL-Logo" className="sidebar-logo" /> {/* CSS set display to none*/}
      <div className="centre-buttons">
        <Link to="/BulkUpload" className="sidebar-button">
          <img src={file_upload_image} alt="Bulk Upload" />
        </Link>
        <Link to="/Upload-History" className="sidebar-button">
          <img src={file_history_image} alt="File Upload History" />
        </Link>
        <Link to="/TeacherDashboard" className="sidebar-button">
          <img src={teacher_dashboard_image} alt="Teacher Dashboard" />
        </Link> */
      </div>
      <button className="sidebar-button" onClick={handleLogout}>
        <img src={logout_image} alt="Logout" />
      </button>
    </div>
  );
}

export default Sidebar;
