/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./UploadHistory.css";
import Table from "../components/Table";
import Sidebar from "../components/Sidebar";
import filterIcon from "../assets/filter.svg";
import { fileUploadPageNumber, documentList, documentDelete } from "../API/api";
import { useUser } from "../UserContext";

function UploadHistory() {
  const user = useUser();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPageNumber = async () => {
    try {
      const result = await fileUploadPageNumber({ startDate, endDate, search });
      setTotalPages(result.count);
    } catch (error) {
      console.error("Error fetching page number:", error);
    }
  };

  useEffect(() => {
    fetchPageNumber();
  }, [user?.uid]);

  const fetchDocumentList = async () => {
    const uid = localStorage.getItem("uid");
    try {
      const result = await documentList(currentPage, {
        startDate,
        endDate,
        search,
        uid
      });
      setAssignments(result.body);
    } catch (error) {
      console.error("Error fetching document list:", error);
    }
  };

  useEffect(() => {
    fetchDocumentList();
  }, [currentPage]);

  const handlePageChange = async (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const onDelete = async (id) => {
    try {
      if (window.confirm("Do you really want to delete this document?")) {
        await documentDelete(id);
        fetchDocumentList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterClick = () => {
    fetchDocumentList();
    fetchPageNumber();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        assignments.find(
          (assignment) => assignment.processState === "processing"
        )
      )
        fetchDocumentList();
    }, 3000);
    return () => clearInterval(interval);
  }, [assignments]);

  return (
    <div className="Upload-History">
      <Sidebar />
      <div className="Upload-History-Main">
        <div className="date-filter-section">
          <div className="start-date-filter">
            <div> Start Date </div>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="date-input"
            />
          </div>
          <div className="end-date-filter">
            <div> End Date </div>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              className="date-input"
            />
          </div>
          <div className="search-box-component">
            <div> Search </div>
            <input
              className="search-box"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </div>
          <button className="filter-button" onClick={handleFilterClick}>
            <img src={filterIcon} alt="Filter" className="filter-icon" /> Filter
          </button>
        </div>
        <div className="table-container">
          <Table assignments={assignments} onDelete={onDelete} />
        </div>
        <div className="pagination">
          <button
            className="page-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1} // Ensure only disabled when on first page
            style={{ visibility: currentPage === 1 ? "hidden" : "visible" }} // Make sure it's visible when necessary
          >
            {"<"}
          </button>

          {currentPage > 2 && (
            <>
              <button
                className={`page-button ${currentPage === 1 ? "active" : ""}`}
                onClick={() => handlePageChange(1)}
              >
                1
              </button>
              {currentPage > 3 && (
                <button className="page-button disabled" disabled>
                  ...
                </button>
              )}
            </>
          )}

          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber === currentPage ||
              pageNumber === currentPage - 1 ||
              pageNumber === currentPage + 1
            ) {
              return (
                <button
                  key={index}
                  className={`page-button ${
                    currentPage === pageNumber ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            }
            return null;
          })}

          {currentPage < totalPages - 1 && (
            <>
              {currentPage < totalPages - 2 && (
                <button className="page-button disabled" disabled>
                  ...
                </button>
              )}
              <button
                className={`page-button ${
                  currentPage === totalPages ? "active" : ""
                }`}
                onClick={() => handlePageChange(totalPages)}
              >
                {totalPages}
              </button>
            </>
          )}

          <button
            className="page-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadHistory;
